<template>
  <modal
    id="modalDescontos"
    titulo="FINANCEIRO.DESCONTO"
    :exibir="exibir"
    :campos="$refs"
    :tamanho="'lg'"
    @fechar="fecharModal"
  >
    <form-descontos
      :desconto="form"
      @form-desconto="obterDesconto"
      @refs="getRefs"
    />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import Modal from '@/components/modal/Modal';
import FormDescontos from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormDescontos,
  },
  data() {
    return {
      desconto: {},
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    obterDesconto(descontoForm) {
      this.desconto = descontoForm;
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      // Adiciona o 'historicoFinanceiro' pq no criar/editar a chave tá diferente no back:
      this.desconto.historicoFinanceiro = this.desconto.historicoFinanceiroId;
      this.$emit('salvar', this.desconto);
    },
  },
};
</script>
