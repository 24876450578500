<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoValor"
        v-model="form.tipoValor"
        :label="$t(`FINANCEIRO.TIPO_VALOR`)"
        :options="opcoes.tiposValor"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="valorReais"
        v-model="form.valor"
        :label="$t('FINANCEIRO.VALOR')"
        :placeholder="$t('FINANCEIRO.VALOR')"
        :maxLength="form.tipoValor === '%' ? 100 : undefined"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-date-only-btn
        ref="dataValidade"
        v-model="form.dataValidade"
        :label="$t('FINANCEIRO.VALIDADE')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoDescontoId"
        v-model="form.tipoDescontoId"
        :label="$t(`FINANCEIRO.TIPO`)"
        :options="opcoes.tipoDesconto"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="historicoLancamentoId"
        v-model="form.historicoLancamentoId"
        :label="$t(`FINANCEIRO.HISTORICO_LANCAMENTO`)"
        :options="opcoes.historicosLancamentos"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="historicoLancamentoAplicaDescontoId"
        v-model="form.historicoLancamentoAplicaDescontoId"
        :label="$t(`FINANCEIRO.HISTORICO_LANCAMENTO_APLICAR_DESCONTO`)"
        :options="opcoes.historicosLancamentos"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service';
// Components:

const REAL = 'R$';
const PORCENTAGEM = '%';
import {
  InputText,
  InputNumber,
  InputSelectSearch,
  InputDateOnlyBtn,
} from '@/components/inputs';

export default {
  props: {
    desconto: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputNumber,
    InputSelectSearch,
    InputDateOnlyBtn,
  },
  data() {
    return {
      form: {},
      opcoes: {
        historicosLancamentos: [],
        tipoDesconto: [],
        tiposValor: [
          {
            value: PORCENTAGEM,
            text: 'Porcentagem - %',
          },
          {
            value: REAL,
            text: 'Reais - R$',
          },
        ],
      },
    };
  },
  watch: {
    form: {
      handler(value) {
        const emitData = {
          descricao: value.descricao,
          tipoDescontoId: value.tipoDescontoId,
          historicoLancamentoId: value.historicoLancamentoId,
          historicoLancamentoAplicaDescontoId:
            value.historicoLancamentoAplicaDescontoId,
          dataValidade: value.dataValidade,
          id: value.id,
          percentual: value.tipoValor === REAL ? 0 : value.valor,
          valor: value.tipoValor === REAL ? value.valor : 0,
        };

        this.$emit('form-desconto', emitData);
      },
      deep: true,
    },
    // 'form.tipoValor': {
    //   handler(value) {
    //     if (value) {
    //       this.form.valor = 0;
    //     }
    //   },
    // },
  },
  mounted() {
    const desconto = JSON.parse(JSON.stringify(this.desconto));
    if (desconto.valor > 0) {
      this.form = {
        ...desconto,
        tipoValor: REAL,
        valor: Number(desconto.valor),
      };
    } else {
      this.form = {
        ...desconto,
        tipoValor: PORCENTAGEM,
        valor: Number(desconto.percentual),
      };
    }
    this.$emit('refs', this.$refs);
    this.getHistoricosLancamentos();
    this.getTipoDesconto();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getHistoricosLancamentos() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEnumPor(307)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.historicosLancamentos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoDesconto() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-descontos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoDesconto = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
