<template>
  <b-row class="mb-3">
    <b-col md="4">
      <input-text
        ref="filtroPreco"
        v-model="filtro.descricao"
        :label="$t('ACADEMICO_MATRICULAS.FILTRO_LABEL')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        @enter="buscar"
      />
    </b-col>

    <b-col md="auto">
      <b-button
        class="botao-acao-filtro w-100 my-3"
        variant="primary"
        @click="buscar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        class="botao-acao-filtro w-100 my-3"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Components:
import { InputText } from '@/components/inputs/index';

export default {
  components: {
    InputText,
  },
  data() {
    return {
      filtro: {
        descricao: null,
      },
    };
  },
  methods: {
    buscar() {
      this.$emit('filtrar', this.filtro);
    },
    limparFiltro() {
      this.filtro.descricao = null;
      this.$emit('limpar-filtro', (this.filtro = { descricao: '' }));
    },
  },
};
</script>
