<template>
  <div>
    <titulo-descontos @cadastrar="openModal" />

    <filtro-desconto
      @filtrar="getFiltro"
      @limpar-filtro="getDescontosPorFiltroPaginado"
    />

    <Tabela
      :items="descontos"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getDescontosPorFiltroPaginado"
    />

    <modal-desconto
      :form="modais.descontoDados"
      :exibir="modais.desconto"
      @salvar="salvar"
      @fechar="closeModal('desconto')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Service & Models:
import DescontosService from '@/common/services/descontos/descontos.service';

// Components:
import Tabela from './components/Tabela';
import ModalDesconto from './components/Modal';
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroDesconto from '@/views/financeiro/estrutura/descontos/components/Filtro';
import TituloDescontos from '@/views/financeiro/estrutura/descontos/components/TituloDescontos.vue';

export default {
  components: {
    TituloDescontos,
    Tabela,
    ModalDesconto,
    Paginacao,
    FiltroDesconto,
  },
  data() {
    return {
      descontos: [],
      expressao: '',
      modais: {
        desconto: false,
        descontoDados: {},
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getDescontosPorFiltroPaginado();
  },
  methods: {
    getFiltro(buscarPor) {
      this.expressao = buscarPor;
      this.getDescontosPorFiltroPaginado();
    },
    getDescontosPorFiltroPaginado(expressao) {
      this.expressao = expressao === '' ? expressao : this.expressao;
      this.$store.dispatch(START_LOADING);
      DescontosService.obterDescontosPorFiltroPaginado(
        this.expressao,
        this.paginacao
      )
        .then(({ data }) => {
          this.descontos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar(item) {
      item.id ? this.editar(item) : this.criar(item);
    },
    criar(item) {
      this.$store.dispatch(START_LOADING);
      DescontosService.criar(item)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.DESCONTO_CRIADO')
          );
          this.getDescontosPorFiltroPaginado();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('desconto');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar(item) {
      this.$store.dispatch(START_LOADING);
      DescontosService.editar(item)
        .then(() => {
          this.closeModal('desconto');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.DESCONTO_EDITADO')
          );
          this.getDescontosPorFiltroPaginado();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('FINANCEIRO.DESCONTO_CONFIMAR_EXCLUSAO')
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      DescontosService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.DESCONTO_EXCLUIDO')
          );
          this.getDescontosPorFiltroPaginado();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
